.product-analysis-column {
  @apply h-full border-x-blue-50 border-y-blue-50 flex flex-col
}

.product-analysis-column-header {
  @apply flex flex-row gap-4 p-4 items-center border-b-[1px] border-y-blue-50 grow-0 shrink basis-auto
}

.product-analysis-column-content {
  @apply flex grow shrink basis-auto
}
